<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageProductRegistrationHeader />
      <ManageProductRegistrationSearch :companies="companies" @search="getPagination" />
      <ManageProductRegistrationContent :companies="companies" :pagination="pagination" @refresh="getPagination" />
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageProductRegistrationHeader from './Header'
  import ManageProductRegistrationContent from './Content'
  import ManageProductRegistrationSearch from './Search'
  export default {
    name: 'ProductRegistration',
    components: { ManageProductRegistrationSearch, ManageProductRegistrationContent, ManageProductRegistrationHeader },
    data () {
      return {
        pagination: {
          list: [],
          pageNum: this.pageNum ? this.pageNum : 1,
        },
        pageDataSize: 10,
        companies: [],
      }
    },
    computed: {
      pageNum () {
        return this.$route.params.pageNum
      },
      keyword () {
        return this.$route.query.keyword
      },
      searchField () {
        return this.$route.query.searchField
      },
      company () {
        return this.$route.query.company
      },
      sdt () {
        return this.$route.query.sdt
      },
      edt () {
        return this.$route.query.edt
      },
      regSdt () {
        return this.$route.query.regSdt
      },
      regEdt () {
        return this.$route.query.regEdt
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
      this.getCompanies()
    },
    methods: {
      async getPagination (payload) {
        const url = '/manage/product/registration/registrations'
        const params = payload || {}
        params.pageNum = this.pageNum
        params.pageDataSize = this.pageDataSize
        params.keyword = this.keyword ? this.keyword : ''
        params.searchField = this.searchField ? this.searchField : ''
        params.company = this.company ? this.company : ''
        params.sdt = this.sdt || ''
        params.edt = this.edt || ''
        params.regSdt = this.regSdt || ''
        params.regEdt = this.regEdt || ''
        await this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getCompanies () {
        const url = '/company/company/items'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.companies = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style scoped>

</style>
