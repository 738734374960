<template>
  <v-row class="mt-10">
    <v-col cols="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="startDate"
            label="구매일 시작"
            placeholder="Start"
            dense
            hide-details
            prepend-inner-icon="mdi-calendar-check"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="startDate"
          locale="ko"
          type="date"
          @change="saveStartDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="endDate"
            label="구매일 종료"
            placeholder="End"
            dense
            hide-details
            prepend-inner-icon="mdi-calendar-check"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="endDate"
          locale="ko"
          type="date"
          @change="saveEndDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="regStartDate"
            label="신청일 시작"
            placeholder="Start"
            dense
            hide-details
            prepend-inner-icon="mdi-calendar-check"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="regStartDate"
          locale="ko"
          type="date"
          @change="saveRegStartDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-menu>
        <template #activator="{ attrs, on }">
          <v-text-field
            v-model="regEndDate"
            label="신청일 종료"
            placeholder="End"
            dense
            hide-details
            prepend-inner-icon="mdi-calendar-check"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="regEndDate"
          locale="ko"
          type="date"
          @change="saveRegEndDate"
        />
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-autocomplete
        v-if="parseCompany && parseCompany.length > 0"
        v-model="company.idx"
        :items="parseCompany"
        :search-input.sync="companyKeyword"
        label="Store"
        dense
        hide-details
        placeholder="업체 검색"
        no-data-text="검색된 업체가 없습니다."
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="searchField"
        :items="searchFields"
        label="Field"
        dense
        hide-details
        placeholder="조건"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="keyword"
        label="Keyword"
        placeholder="검색어를 입력해주세요."
        dense
        hide-details
        append-icon="mdi-magnify"
        @keydown="enterSearch"
        @click:append="search"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageProductSearch',
    props: [
      'companies',
    ],
    data () {
      return {
        status: this.$route.query.status ? this.$route.query.status : '',
        type: this.$route.query.type ? this.$route.query.type : '',
        sdt: this.$route.query.sdt ? this.$parseDate(this.$route.query.sdt) : null,
        edt: this.$route.query.edt ? this.$parseDate(this.$route.query.edt) : null,
        regSdt: this.$route.query.regSdt ? this.$parseDate(this.$route.query.regSdt) : null,
        regEdt: this.$route.query.regEdt ? this.$parseDate(this.$route.query.regEdt) : null,
        keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
        searchField: this.$route.query.searchField ? this.$route.query.searchField : null,
        searchFields: [
          {
            text: '전체',
            value: null,
          },
          {
            text: '이름',
            value: 'username',
          },
          {
            text: '아이디',
            value: 'id',
          },
          {
            text: '상품명',
            value: 'productName',
          },
        ],
        items: {
          status: [
            {
              text: '전체',
              value: '',
            },
            {
              text: '대기',
              value: 'pending',
            },
            {
              text: '승인',
              value: 'confirm',
            },
            {
              text: '반려',
              value: 'reject',
            },
          ],
        },
        company: {
          idx: this.$route.query.company ? parseInt(this.$route.query.company | String) : null,
        },
        companyKeyword: null,
      }
    },
    computed: {
      parseCompany () {
        const array = []
        array.push({
          text: '전체',
          value: null,
        })
        for (const company of this.companies) {
          array.push(company)
        }
        return array
      },
      startDate: {
        get () {
          if (this.sdt) {
            const d = new Date()
            const year = this.sdt.split('-')[0]
            const month = this.sdt.split('-')[1]
            const day = this.sdt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.sdt = value
        },
      },
      endDate: {
        get () {
          if (this.edt) {
            const d = new Date()
            const year = this.edt.split('-')[0]
            const month = this.edt.split('-')[1]
            const day = this.edt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.edt = value
        },
      },
      regStartDate: {
        get () {
          if (this.regSdt) {
            const d = new Date()
            const year = this.regSdt.split('-')[0]
            const month = this.regSdt.split('-')[1]
            const day = this.regSdt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.regSdt = value
        },
      },
      regEndDate: {
        get () {
          if (this.regEdt) {
            const d = new Date()
            const year = this.regEdt.split('-')[0]
            const month = this.regEdt.split('-')[1]
            const day = this.regEdt.split('-')[2]
            d.setFullYear(year)
            d.setMonth(month - 1)
            d.setDate(day)
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            d.setMilliseconds(0)
            const date = this.$moment(d).format('YYYY-MM-DD')
            return date
          } else {
            return null
          }
        },
        set (value) {
          this.regEdt = value
        },
      },
    },
    methods: {
      search () {
        const data = {
          keyword: this.keyword ? this.keyword : '',
          searchField: this.searchField ? this.searchField : '',
          company: this.company.idx ? this.company.idx : '',
          sdt: this.getQueryDate(this.startDate, 'start') ? this.startDate : '',
          edt: this.getQueryDate(this.endDate, 'end') ? this.endDate : '',
          regSdt: this.getQueryDate(this.regStartDate, 'start') ? this.regStartDate : '',
          regEdt: this.getQueryDate(this.regEndDate, 'end') ? this.regEndDate : '',
        }
        this.$router.push({
          path: '/manage/product/registration/1',
          query: data,
        }, () => {
          this.$emit('search', data)
        })
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.search()
        }
      },
      saveStartDate (date) {
        this.sdt = date
      },
      saveEndDate (date) {
        this.edt = date
      },
      saveRegStartDate (date) {
        this.regSdt = date
      },
      saveRegEndDate (date) {
        this.regEdt = date
      },
      getQueryDate (date, type) {
        if (date) {
          const year = date.split('-')[0]
          const month = date.split('-')[1]
          const day = date.split('-')[2]
          let d
          if (type === 'start') {
            d = new Date(year, month - 1, day, 0, 0, 0, 0)
          } else {
            d = new Date(year, month - 1, day, 23, 59, 59, 999)
          }
          return d.getTime()
        } else {
          return null
        }
      },
    },
  }
</script>

<style scoped>

</style>
