<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-subheader>
        신규상품 신청관리
      </v-subheader>
      <v-sheet class="px-3 pb-0 d-flex" />
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>
                <v-checkbox v-model="checkAll" />
              </th>
              <th
                v-for="(col, i) in cols"
                :id="'product-' + i"
                :key="i"
                class="text-subtitle-1 text-center"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody v-if="pagination && pagination.list && pagination.list.length > 0">
            <tr v-for="item in pagination.list" :key="`registration-${item.idx}`">
              <td>
                <v-checkbox v-model="selected" :value="item.idx" />
              </td>
              <td class="text-center text-truncate">
                <v-tooltip v-if="!item.alias" bottom>
                  <template #activator="{ attrs, on }">
                    <span v-bind="attrs" v-on="on">
                      {{ item.users.id }}
                    </span>
                  </template>
                  <span>
                    {{ item.users.username }}
                  </span>
                </v-tooltip>
                <template v-else>
                  <v-tooltip bottom>
                    <template #activator="{ attrs, on}">
                      <span v-bind="attrs" v-on="on">
                        {{ item.alias.id }}
                      </span>
                    </template>
                    <span>
                      {{ item.alias.username }}
                    </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ attrs, on }">
                      <v-chip
                        label
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.users.id }}
                      </v-chip>
                    </template>
                    <span>
                      {{ item.users.username }}
                    </span>
                  </v-tooltip>
                </template>
              </td>
              <td class="text-center text-truncate">
                {{ item.product.company.companyName }}
              </td>
              <td class="text-center text-truncate">
                <router-link :to="`/product/detail/${item.product.idx}`">
                  {{ item.product.productName }}
                </router-link>
              </td>
              <td class="text-center text-truncate">
                <option-edit :item="item" @refresh="refreshPagination" />
              </td>
              <td class="text-center text-truncate">
                {{ item.product ? $parseDate(item.purchaseDate) : '-' }}
              </td>
              <td class="text-center text-truncate">
                {{ $parseDateSecond(item.registerDate) }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center">
                검색된 신규상품 신청내역이 존재하지 않습니다.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-sheet width="100%" class="pb-15 pb-md-3 mt-3">
        <v-row>
          <v-col cols="12" md="2">
            <v-btn
              large
              class="mx-2"
              @click="deleteProductRegistrations"
            >
              삭제
            </v-btn>
          </v-col>
          <v-col cols="12" md="8">
            <Pagination ref="pagination" :pagination="pagination" :base-url="'/manage/product/registration/'" :query="$route.query" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  import Pagination from '@/components/core/Pagination'
  import OptionEdit from '@/views/manage/product/registration/OptionEdit'
  export default {
    name: 'ManageProductRegistrationContent',
    components: { OptionEdit, Pagination },
    props: {
      pagination: {},
      companies: {},
    },
    data () {
      return {
        cols: ['Id', 'Company', 'Product', 'Option', 'Purchase', 'Reg'],
        isLoading: false,
        showRegister: false,
        selected: [],
      }
    },
    computed: {
      checkAll: {
        get () {
          return this.pagination ? (this.selected ? (this.selected.length === this.pagination.list.length) : false) : false
        },
        set (value) {
          const select = []
          if (value) {
            this.pagination.list.forEach(user => {
              select.push(user.idx)
            })
            this.selected = select
          } else {
            this.selected = []
          }
        },
      },
    },
    methods: {
      closeEditor (product) {
        if (product) {
          product.showEditor = false
        } else {
          this.showRegister = false
        }
        this.refreshPagination()
      },
      async deleteProductRegistrations () {
        const msg = `${this.selected.length}개의 신규상품 신청을 삭제하시겠습니까?`
        if (confirm(msg)) {
          const url = '/manage/product/registration'
          await this.$axios.delete(url, { data: this.selected }).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              this.refreshPagination()
            }
          })
        }
      },
      refreshPagination () {
        this.$emit('refresh')
      },
    },
  }
</script>

<style scoped>

</style>
