<template>
  <div>
    <v-dialog
      v-model="productRegistration.viewOptions"
      max-width="600"
    >
      <template #activator="{ attrs, on }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          {{ item.options.optionContent }}
        </span>
      </template>
      <v-card>
        <v-card-title>
          <p class="text-subtitle-2">
            {{ item.product.productName }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="productRegistration.options.idx"
            :items="options"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'OptionEdit',
    props: {
      item: {
        type: Object,
        default: () => {
          return {
          }
        },
      },
    },
    data () {
      return {
        productRegistration: this.item,
        options: [],
      }
    },
    computed: {
      viewOptions () {
        return this.productRegistration.viewOptions
      },
      optionIdx () {
        if (this.productRegistration.options.idx) {
          return this.productRegistration.options.idx
        } else {
          return null
        }
      },
    },
    watch: {
      viewOptions () {
        if (this.viewOptions) {
          this.getOptions()
        }
      },
      optionIdx () {
        this.updateProductOptions()
      },
    },
    created () {
    },
    methods: {
      async updateProductOptions () {
        const url = '/manage/product/options/update'
        const params = {
          idx: this.productRegistration.idx,
          options: this.productRegistration.options,
        }
        await this.$axios.post(url, params).then(res => {
          if (res.data.status === 'SUCCESS') {
            this.productRegistration.viewOptions = false
            this.$emit('refresh')
          } else {
            alert(res.data.message || '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async getOptions () {
        const productIdx = this.productRegistration.product.idx
        const params = {
          productIdx: productIdx,
        }
        await this.$axios.get('/manage/product/options/only', { params: params }).then(res => {
          if (res && res.data) {
            this.options = res.data
          }
        }).catch(e => {
          console.log(e)
        })
      },
    },
  }
</script>

<style scoped>

</style>
